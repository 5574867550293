import * as React from "react"
import { graphql } from "gatsby"

import Layout from "@components/layout/layout"
import ContactBox from "@components/contactBox"
import CtaSection from "@components/ctaSection"

const Contact = ({ data }: any) => {
  const { heroData, ctaSectionData } = data.pageData

  return (
    <Layout title="Contact" description="">
      <ContactBox {...heroData} />
      <CtaSection {...ctaSectionData} />
    </Layout>
  )
}

export default Contact

export const pageQuery = graphql`
  query {
    pageData: contactJson {
      heroData: hero {
        title
        text
      }
      ctaSectionData: ctaSection {
        title
        cta {
          text
          url
        }
      }
    }
  }
`
