import * as React from "react"
import { useState } from "react"
import { useForm } from "react-hook-form"
import cx from "classnames"

import { FormContainer, TextField, Field } from "@components/elements/form"
import Cta from "@components/elements/cta"
import { formDataEncode } from "@lib/utils"

import styles from "./contactForm.module.scss"

const FORM_NAME = "Contact"
const FORM_STATE_SUCCESS = "SUCCESS"
const FORM_STATE_ERROR = "ERROR"
const REQUIRED_TEXT_MSG = "Is required"

interface ContactFormProps {
  className?: string
}

const ContactForm = ({ className }: ContactFormProps) => {
  const [formState, setFormState] = useState("")
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm()

  const onSubmit = (formData: object) => {
    setFormState("")

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: formDataEncode({ "form-name": FORM_NAME, ...formData }),
    })
      .then(response => {
        const isSuccess = response.status === 200

        reset()
        setFormState(isSuccess ? FORM_STATE_SUCCESS : FORM_STATE_ERROR)
      })
      .catch(() => {
        setFormState(FORM_STATE_ERROR)
      })
  }

  return (
    <form
      className={cx(styles.form, className)}
      onSubmit={handleSubmit(onSubmit)}
      netlify-honeypot="bot-field"
      data-netlify="true"
      name={FORM_NAME}
      noValidate
    >
      <input type="hidden" name="bot-field" />
      <input type="hidden" name="form-name" value={FORM_NAME} />
      <FormContainer>
        <TextField
          {...register("first-name", { required: REQUIRED_TEXT_MSG })}
          name="first-name"
          label="First Name"
          isRequired
          errors={errors["first-name"]?.message}
        />
        <TextField
          {...register("last-name", { required: REQUIRED_TEXT_MSG })}
          name="last-name"
          label="Last Name"
          isRequired
          errors={errors["last-name"]?.message}
        />
        <TextField
          {...register("phone")}
          name="phone"
          label="Phone Number"
          isWide
        />
        <TextField
          {...register("email", {
            required: REQUIRED_TEXT_MSG,
            pattern: {
              value: /^$|\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/i,
              message: "Wrong email format",
            },
          })}
          name="email"
          label="Email Address"
          isRequired
          errors={errors.email?.message}
          isWide
        />
        <TextField
          {...register("title")}
          name="title"
          label="Job Title"
          isWide
        />
        <TextField
          {...register("company")}
          name="company"
          label="Company / Institution"
          errors={errors.company?.message}
          isWide
        />
        <Field isWide>
          <TextField
            {...register("message", { required: REQUIRED_TEXT_MSG })}
            name="message"
            label="Message"
            type="textarea"
            isRequired
            errors={errors.message?.message}
          />
          <div className={styles.note}>
            REQUIRED FIELD<span className={styles.noteAsterix}>*</span>
          </div>
        </Field>
        <div className={styles.submitContainer}>
          {formState === FORM_STATE_ERROR && (
            <div className={cx(styles.submitTitle, styles.isError)}>
              Oops! Something went wrong!
              <br />
              Please try later.
            </div>
          )}
          {formState === FORM_STATE_SUCCESS && (
            <div className={cx(styles.submitTitle, styles.isSuccess)}>
              Thank you for reaching out!
              <br /> We’ll be in touch.
            </div>
          )}
          <Cta className={styles.submitButton} text="Submit" type="submit" />
        </div>
      </FormContainer>
    </form>
  )
}

export default ContactForm
