import * as React from "react"

import Section from "@components/elements/section"
import Wysiwyg from "@components/elements/wysiwyg"
import ContactForm from "@components/elements/contactForm"

import styles from "./contactBox.module.scss"

interface ContactBoxProps {
  title?: string
  text?: string
}

const ContactBox = ({ title, text }: ContactBoxProps) => {
  return (
    <Section
      className={styles.contactBox}
      containerClassName={styles.container}
    >
      {title && <h1 className={styles.title}>{title}</h1>}
      <Wysiwyg className={styles.text} contentHtml={text} />
      <div className={styles.box}>
        <ContactForm />
      </div>
    </Section>
  )
}

export default ContactBox
